import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const illerListesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/iller`,
  });
};

const ilcelerListesi = async (il_id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/ilceler/${il_id}`,
  });
};

export { illerListesi, ilcelerListesi };
