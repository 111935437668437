<template>
  <div class="text-center">
    <v-dialog persistent v-model="modal" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ cardText }}
        </v-card-title>
        <v-card-text class="mt-5">
          <v-text-field
            v-model="firma_bilgisi.unvan"
            name="unvan"
            id="unvan"
            label="Ünvan"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="firma_bilgisi.ad"
            name="ad"
            id="ad"
            label="ad"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="firma_bilgisi.soyad"
            name="soyad"
            id="soyad"
            label="soyad"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="firma_bilgisi.gsm"
            name="gsm"
            id="gsm"
            label="gsm"
            v-mask="'0 (###) ### ## ##'"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="firma_bilgisi.vergi_dairesi"
            name="vergi_dairesi"
            id="vergi_dairesi"
            label="vergi_dairesi"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="firma_bilgisi.vergi_numarasi"
            name="vergi_numarasi"
            id="vergi_numarasi"
            label="vergi_numarasi"
            outlined
          ></v-text-field>
          <Autocomplete
            :model="firma_bilgisi.il_id"
            :value="firma_bilgisi.il_id"
            :items="iller"
            itemText="ad"
            itemValue="id"
            clearable
            label="İl"
            @changed="(i) => il_degisti(i)"
            outlined
          />
          <Autocomplete
            :model="firma_bilgisi.ilce_id"
            :value="firma_bilgisi.ilce_id"
            :items="ilceler"
            itemText="ad"
            itemValue="id"
            clearable
            label="İlçeler"
            @changed="(i) => (firma_bilgisi.ilce_id = i)"
            outlined
          />
          <v-text-field
            v-model="firma_bilgisi.adres"
            name="adres"
            id="adres"
            label="adres"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="firma_bilgisi.mail"
            name="mail"
            id="mail"
            label="mail"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="red" @click="$emit('hideDialog', null)"
            >Vazgeç</v-btn
          >
          <v-btn color="primary" @click="crud"> {{ buttonText }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  firmaBilgileri,
  firmaGuncelle,
  firmaKayit,
} from "../../query/firmalar";
import { schemaFirma, validate } from "../../schemas";
import { illerListesi, ilcelerListesi } from "../../query/bolgeler";
import Autocomplete from "@/components/fields/AutoComplete";

export default {
  watch: {
    async modal() {
      this.crudFunction(this.gelen_id);
    },
  },

  props: { modal: null, gelen_id: null },
  data() {
    return {
      firma_bilgisi: [],
      gelen_kasa_bilgisi: [],
      iller: [],
      ilceler: [],
      buttonText: "Kaydet",
      cardText: "Yeni Firma",
      crud: this.kaydet,
    };
  },

  methods: {
    async crudFunction(gelen_id) {
      if (this.gelen_id != null) {
        this.firma_bilgisi = await firmaBilgileri(gelen_id);
        this.il_degisti(this.firma_bilgisi.il_id);
        this.buttonText = "Güncelle";
        this.cardText = "Bilgileri Güncelle";
        this.crud = this.guncelle;
      } else {
        this.firma_bilgisi = [];
        this.buttonText = "Kaydet";
        this.cardText = "Yeni Firma";
        this.crud = this.kaydet;
      }
      this.iller = await illerListesi();
    },
    async hatali(gelen_mesaj) {
      await this.$dialog.error({
        text: gelen_mesaj,
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Tamam",
          },
        },
      });
    },
    async kaydet() {
      const data = {
        unvan: this.firma_bilgisi.unvan,
        ad: this.firma_bilgisi.ad,
        soyad: this.firma_bilgisi.soyad,
        gsm: this.firma_bilgisi.gsm,
        vergi_dairesi: this.firma_bilgisi.vergi_dairesi,
        vergi_numarasi: this.firma_bilgisi.vergi_numarasi,
        il_id: this.firma_bilgisi.il_id,
        ilce_id: this.firma_bilgisi.ilce_id,
        adres: this.firma_bilgisi.adres,
        mail: this.firma_bilgisi.mail,
      };
      // validation
      const degerlendirme = validate(schemaFirma, data);
      if (degerlendirme.length === 0) {
        const sonuc = await firmaKayit(data);
        if (sonuc !== undefined) {
          this.$emit("hideDialog", sonuc);
        }
      } else {
        this.hatali(degerlendirme[0].message);
      }
    },
    async guncelle() {
      const sonuc = await firmaGuncelle(
        this.firma_bilgisi.unvan,
        this.firma_bilgisi.ad,
        this.firma_bilgisi.soyad,
        this.firma_bilgisi.gsm,
        this.firma_bilgisi.vergi_dairesi,
        this.firma_bilgisi.vergi_numarasi,
        this.firma_bilgisi.il_id,
        this.firma_bilgisi.ilce_id,
        this.firma_bilgisi.adres,
        this.firma_bilgisi.mail,
        this.gelen_id
      );
      if (sonuc !== undefined) {
        this.$emit("hideDialog");
      }
    },
    async il_degisti(id) {
      console.log("zey", id);
      if (!id) {
        this.ilceler = [];
      } else {
        this.ilceler = await ilcelerListesi(id);
      }
      this.firma_bilgisi.il_id = id;
    },
  },
  components: {
    Autocomplete,
  },
};
</script>
